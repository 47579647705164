import React from 'react'
import Header from '../components/global/header/header'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import Helmet from 'react-helmet'

import favicon from '../images/favicon.ico'

import { withIntl } from '../i18n'

class NotFoundPage extends React.Component {
  componentDidMount() {
    if(document.body.classList.contains('noscroll')) {
      document.body.classList.remove('noscroll');
    }
  }

  render() {
    return (
            <PageTransition
              defaultStyle={{
                transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
                position: 'relative',
                width: '100%',
                opacity: 0,
                maxWidth: '2000px'
              }}

              transitionStyles={{
                entering: { opacity: 1 },
                entered: { opacity: 1 },
                exiting: { opacity: 0, }
              }}

              transitionTime={1250}
            >
              <Header headerStyle="std" />
              <h1>NOT FOUND</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
              <Footer />
            </PageTransition>
      
    );
  }
}

export default withIntl(NotFoundPage)